// Here you can add other styles
/*=== MEDIA QUERY ===*/
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800,900");
body {
  line-height: 1.5;
  font-family: "Muli", sans-serif;
}

p, li, input, select, button {
  font-family: "Muli", sans-serif;
  color: #666;
  font-size: 14px;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Muli", sans-serif;
  font-weight: 600;
  color: #333;
}

p {
  color: #666;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

p {
  color: #888;
}

label {
  font-size: 14px;
  letter-spacing: 0;
  color: #848484;
}

.header-border {
  border-bottom: 1px solid #ddd;
}

.nav-pills .nav-link {
  font-size: 16px;
}

@media (max-width: 480px) {
  .nav-pills .nav-link {
    font-size: 12px;
  }
}

.nav-pills.with-background .nav-item {
  margin-right: 10px;
}

.nav-pills.with-background .nav-item:last-child {
  margin-right: 0;
}

.nav-pills.with-background .nav-link {
  background: white;
  padding: 10px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.nav-pills.with-background .nav-link:hover {
  color: #3EA3FF;
}

.nav-pills.with-background .nav-link.active {
  background: #3EA3FF;
  position: relative;
  transition: 0s;
}

.nav-pills.with-background .nav-link.active:hover {
  color: #fff;
}

.nav-pills.with-background .nav-link.active:after {
  content: '';
  position: absolute;
  left: 42%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #3EA3FF;
  clear: both;
  transition: all .2s ease-in 0s;
}

.nav-pills.transparent .nav-link {
  background: transparent;
  margin-bottom: 20px;
  color: #848484;
  font-size: 24px;
}

@media (max-width: 768px) {
  .nav-pills.transparent .nav-link {
    font-size: 17px;
  }
}

@media (max-width: 480px) {
  .nav-pills.transparent .nav-link {
    font-size: 12px;
  }
}

.nav-pills.transparent .nav-link.active {
  background: transparent;
  color: #666;
}

ul.pagination li {
  margin-right: 10px;
}

ul.pagination li a {
  border-radius: 3px !important;
  font-size: 16px;
  padding: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-color: transparent;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  color: #666;
}

ul.pagination .active a {
  background-color: #3EA3FF !important;
  color: #fff !important;
  border-color: transparent !important;
}

ul.social-circle-icons li {
  margin-right: 7px;
}

ul.social-circle-icons li:last-child {
  margin-right: 0;
}

ul.social-circle-icons li a {
  display: block;
  cursor: pointer;
  font-size: 15px;
  line-height: 38px;
  color: #3EA3FF;
  height: 38px;
  width: 38px;
  border: 2px solid #3EA3FF;
  background: #fff;
  border-radius: 100%;
}

ul.social-circle-icons li a:hover {
  text-decoration: none !important;
  color: #fff;
  background: #3EA3FF;
}

.store-list h4 {
  font-size: 20px;
  margin-bottom: 20px;
  letter-spacing: 0.01em;
  color: #666;
}

.store-list ul {
  list-style: none;
}

.store-list ul li {
  display: block;
  font-size: 15px;
  margin-bottom: 8px;
}

.store-list ul li a {
  display: block;
  color: #848484;
}

.store-list ul li a:hover {
  text-decoration: none !important;
  color: #3EA3FF;
}

@media (max-width: 768px) {
  .store-list {
    margin-bottom: 15px;
  }
}

.store-list .btn {
  margin-top: 35px;
}

/**
 *
 * Base Elements
 *
 */
ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  transition: all .3s ease-in 0s;
  color: #000;
}

iframe {
  border: 0;
}

a:focus, a:hover {
  text-decoration: none !important;
  outline: 0;
  color: #3EA3FF;
}

.form-control {
  border-radius: 2px;
  height: 50px;
  background-color: transparent;
  color: #666;
  box-shadow: none;
  font-size: 15px;
}

.form-control:focus {
  color: #666;
  box-shadow: none;
  border: 1px solid #3EA3FF;
  background-color: transparent;
}

textarea.form-control {
  padding: 20px;
}

.form-control::-webkit-input-placeholder {
  color: #666;
}

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #666;
}

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #666;
}

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #666;
}

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #666;
}

/* Microsoft Edge */
.mt-20 {
  margin-top: 20px;
}

.btn:focus {
  color: #ddd;
}

.w-100 {
  width: 100%;
}

.margin-0 {
  margin: 0 !important;
}

button {
  cursor: pointer;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.bg-shadow {
  background-color: #fff;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.08);
  padding: 20px;
}

.category-section {
  padding: 25px 0;
}

.how-it-works-section {
  padding: 100px 0 30px;
  background-color: #3EA3FF;
}

.category-title {
  font-size: 26px;
  vertical-align: -webkit-baseline-middle;
  background-color: #fff;
  padding: 10px;
  color: #000 !important;
}

.category-heading {
  margin-bottom: 10px;
}

.category-top-message {
  margin-bottom: 25px;
}

.category-heading img {
  vertical-align: -webkit-baseline-middle;
}

.category-checkbox-row {
  margin-left: 1px;
}

.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-title {
  position: relative;
  margin-bottom: 35px;
  text-align: center;
}

.section-title h2 {
  font-size: 30px;
  letter-spacing: 0.05em;
  text-align: center;
}

/*.section-title h2:before {
  content: " ";
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  width: 127px;
  height: 2px;
  background-color: #e0e0e0;
}*/

/*.section-title h2:after {
  content: " ";
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  width: 40px;
  height: 5px;
  background-color: #3EA3FF;
}*/

.section-title p {
  font-size: 18px;
  line-height: 30px;
  color: #848484;
}

.user-profile, .dashboard {
  padding-top: 50px;
}

.overly {
  position: relative;
}

.overly:before {
  content: '';
  background: rgba(0, 0, 0, 0.51);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-wrapper {
  padding: 70px 0;
}

.social-media-icons ul li {
  display: inline-block;
}

.social-media-icons ul li a {
  font-size: 18px;
  color: #333;
  display: inline-block;
  padding: 7px 12px;
  color: #fff;
}

.social-media-icons ul li .twitter {
  background: #00aced;
}

.social-media-icons ul li .facebook {
  background: #3b5998;
  padding: 7px 18px;
}

.social-media-icons ul li .googleplus {
  background: #dd4b39;
}

.social-media-icons ul li .dribbble {
  background: #ea4c89;
}

.social-media-icons ul li .instagram {
  background: #bc2a8d;
}

/**
 *
 * Button Styles
 *
 */
.btn {
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 4px;
}

.btn-main, .btn-main-sm {
  background: #3EA3FF;
  color: #fff;
}

.btn-main:hover, .btn-main-sm:hover {
  background: #3EA3FF;
  color: #fff;
}

.btn-main-sm {
  padding: 6px 20px;
  font-size: 12px;
}

.btn-secondary {
  background: #fff;
  color: #000;
  border-color: #fff;
}

.btn-secondary:hover {
  background: #3EA3FF;
  color: #fff;
  border-color: #fff;
}

.btn-transparent {
  background: transparent;
  color: #3EA3FF;
  border: 1px solid #3EA3FF;
}

.btn-transparent:hover {
  background: #3EA3FF;
  color: #fff;
}

.btn-transparent-white {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.btn-transparent-white:hover {
  background: #fff;
  color: #848484;
}

.btn-group .btn i {
  font-size: 16px;
  line-height: 40px;
}

.btn-group .btn:focus {
  color: #d0d0d0;
}

.btn-group .btn-transparent {
  color: #d0d0d0;
  border: 1px solid #d0d0d0;
  padding: 0;
  width: 40px;
}

.btn-group .btn-transparent:hover {
  color: #e6b322;
  background: transparent;
}

.btn-active {
  color: #e6b322;
}

.btn:focus {
  box-shadow: none;
}

.click {
  cursor: pointer;
}

/**
 *
 * Card Styles
 *
 */
.card-main {
  border-radius: 2px;
  border: none;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.mb-30 {
  margin-bottom: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.border-bottom {
  border-bottom: 1px solid #dedede;
}

.bl-none {
  border-left: none !important;
}

.alt-color {
  color: #c7c7c7;
}

.success {
  color: #1fcc2e;
}

.page-title {
  background: #3EA3FF;
  padding: 35px 0;
}

.page-title h3 {
  color: #fff;
  font-size: 42px;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
}

ul.pagination li {
  margin-right: 10px;
}

ul.pagination li a {
  font-weight: 600;
  border-radius: 3px !important;
  font-size: 16px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-color: transparent;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  color: #666;
}

ul.pagination li a:hover {
  background-color: #3EA3FF !important;
  color: #fff !important;
  border-color: transparent !important;
}

ul.pagination .active a {
  background-color: #3EA3FF !important;
  color: #fff !important;
  border-color: transparent !important;
}

.page-search {
  background: #3EA3FF;
  padding: 30px 0;
}

.page-search .advance-search .form-group {
  margin-bottom: 0;
}

.page-search .advance-search .form-control {
  border: 1px solid #fff;
  color: #fff;
}

.page-search .advance-search ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.page-search .advance-search ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.page-search .advance-search :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.page-search .advance-search :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.bg-light {
  background: #fdfdfd !important;
  background-color: #fdfdfd !important;
}

.bg-gray {
  background: #F5F5F5;
}

.bg-1 {
  background: url("assets/img/home/table.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-2 {
  //background: url(assets/img/call-to-action/cta-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-3 {
  //background: url(assets/img/call-to-action/cta-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-4 {
  background: #3EA3FF;
}

.navigation {
  padding: 20px 0;
}

.login-button {
  border: 2px solid #dedede;
  margin-right: 8px;
  padding: 6px 20px !important;
}

.add-button {
  background: #3EA3FF;
  color: #fff;
  padding: 8px 20px !important;
  display: inline-block;
}

.add-button:hover {
  color: #fff;
}

.main-nav .nav-item .nav-link {
  font-size: 16px;
  line-height: 43px;
  padding: 0 20px;
}

.main-nav .nav-item .nav-link span {
  margin-left: 4px;
}

.main-nav .nav-item .nav-link.dropdown-toggle {
  padding-right: 10px;
}

.main-nav .nav-item .dropdown-toggle::after {
  border: none;
}

.main-nav .nav-item.active .nav-link {
  color: #3EA3FF;
}

.main-nav .search .nav-link i {
  font-size: 15px;
  height: 43px;
  width: 43px;
  line-height: 43px;
  color: #3EA3FF;
  border: 1px solid #3EA3FF;
  border-radius: 3px;
  text-align: center;
}

.main-nav .search .input-group {
  width: 300px;
}

.main-nav .search .input-group .input-group-addon {
  background: #3EA3FF;
  color: #fff;
  border: 1px solid #3EA3FF;
}

.main-nav .search .input-group .input-group-addon i {
  font-size: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.dropdown-menu {
  padding: 15px;
  border-radius: 0;
}

.nav-item.dropdown {
  position: relative;
}

.dropdown-item.active, .dropdown-item:active {
  background: #3EA3FF;
}

.dropdown-item {
  font-size: 14px;
  padding: 10px 60px 10px 10px;
}

@media (min-width: 768px) {
  .dropdown-slide {
    position: static;
  }
  .dropdown-slide .open > a, .dropdown-slide .open > a:focus, .dropdown-slide .open > a:hover {
    background: transparent;
  }
  .dropdown-slide.full-width .dropdown-menu {
    left: 0 !important;
    right: 0 !important;
  }
  .dropdown-slide:hover .dropdown-menu {
    display: none;
    opacity: 1;
    display: block;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    opacity: 1;
    visibility: visible;
    color: #777;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  .dropdown-slide .dropdown-menu {
    margin-top: 0;
    border-radius: 0;
    opacity: 1;
    visibility: visible;
    position: absolute;
    padding: 15px;
    border: 1px solid #ebebeb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: .3s all;
    position: absolute;
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    transition: visibility 0.2s, opacity 0.2s, -webkit-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
    transition: visibility 0.2s, opacity 0.2s, transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
    transition: visibility 0.2s, opacity 0.2s, transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99), -webkit-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  }
}

@media (min-width: 768px) and (max-width: 480px) {
  .dropdown-slide .dropdown-menu {
    -webkit-transform: none;
            transform: none;
  }
}

@media (max-width: 480px) {
  .navbar-toggleable-md > .container {
    margin-right: 0;
    margin-left: 0;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.hero-area .content-block h1, .hero-area-2 .content-block h1 {
  color: #fff;
  font-size: 65px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .hero-area .content-block h1, .hero-area-2 .content-block h1 {
    font-size: 40px;
  }
}

.hero-area .content-block p, .hero-area-2 .content-block p {
  color: #fff;
  font-size: 20px;
}

@media (max-width: 768px) {
  .hero-area .content-block p, .hero-area-2 .content-block p {
    font-size: 17px;
  }
}

.hero-area {
  padding: 100px 0 0;
}

.hero-area .content-block {
  padding-bottom: 80px;
}

.hero-area .advance-search {
  background: #fff;
  padding: 40px 30px;
  border-radius: 3px;
  margin-bottom: -50px;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.12);
}

@media (max-width: 480px) {
  .hero-area .advance-search {
    margin-bottom: -80px;
  }
}

@media (max-width: 480px) {
  .hero-area .advance-search .block {
    display: block !important;
  }
}

.hero-area .advance-search .form-control {
  color: #000;
  border-color: #dedede;
}

.hero-area .advance-search button {
  display: block;
}

@media (max-width: 480px) {
  .hero-area .advance-search button {
    width: 100%;
  }
}

.short-popular-category-list {
  margin-top: 40px;
}

.short-popular-category-list h2 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}

.short-popular-category-list ul {
  margin-top: 20px;
}

.short-popular-category-list ul li {
  margin: 0 2px;
}

.short-popular-category-list ul li a {
  border: 1px solid #404040;
  color: #c1c1c1;
  font-weight: 200;
  padding: 4px 10px;
  font-size: 12px;
}

.short-popular-category-list ul li a i {
  margin-right: 2px;
}

.page-breadcrumb {
  background: none;
  margin-top: 10px;
  margin-bottom: 0;
}

.page-breadcrumb li a {
  color: #fff;
}

.page-breadcrumb li.active {
  color: #fff !important;
}

.category-slider {
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.07);
  background: #fff;
  padding: 20px 0;
}

.category-slider .item {
  text-align: center;
}

.category-slider .item a {
  display: inline-block;
  padding: 20px 40px;
  background: #fff;
}

.category-slider .item a h4 {
  font-size: 14px;
  margin-top: 10px;
}

.category-slider .item a i {
  font-size: 40px;
}

.user-dashboard-menu li {
  margin-bottom: 5px;
}

.user-dashboard-menu li.active a {
  background: #3EA3FF;
  color: #fff;
}

.user-dashboard-menu li.active a:hover {
  color: #fff;
}

.user-dashboard-menu li a {
  padding: 10px;
  font-size: 15px;
  display: block;
  font-weight: 600;
  color: #555;
}

.user-dashboard-menu li a:hover {
  color: #3EA3FF;
}

.user-dashboard-menu li a:hover span {
  background: #3EA3FF;
  color: #fff;
}

.user-dashboard-menu li a i {
  margin-right: 5px;
}

.user-dashboard-menu li a span {
  float: right;
  background: #F4F7F9;
  float: right;
  font-size: 10px;
  border-radius: 8px;
  padding: 3px 8px;
  color: #84919B;
}

.user-dashboard-profile {
  text-align: center;
}

.user-dashboard-profile .profile-thumb img {
  width: 130px;
  height: auto;
  border: 5px solid #F4F7F9;
}

.user-dashboard-profile h4 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.user-dashboard-profile .member-time {
  color: #888;
  font-size: 12px;
  margin-bottom: 3px;
}

.user-dashboard-profile .btn-contact {
  background: #1FCA6B;
}

.user-dashboard-profile .btn-offer {
  background: #373C4D;
}

.dashboard-container .product-dashboard-table thead th {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: .4px;
}

.dashboard-container .product-dashboard-table td {
  vertical-align: middle;
  padding: 40px 0;
}

.dashboard-container .product-dashboard-table .product-thumb {
  width: 15%;
}

.dashboard-container .product-dashboard-table .product-details {
  width: 45%;
}

.dashboard-container .product-dashboard-table .product-details span {
  display: block;
  font-size: 14px;
  color: #848484;
}

.dashboard-container .product-dashboard-table .product-details span strong {
  color: #666;
  width: 30%;
  display: inline-block;
}

.dashboard-container .product-dashboard-table .product-details .title {
  font-size: 16px;
}

.dashboard-container .product-dashboard-table .product-details .status.active {
  color: #59d659;
}

.dashboard-container .product-dashboard-table .product-category {
  width: 15%;
  text-align: center;
}

.dashboard-container .product-dashboard-table .action {
  width: 20%;
  text-align: center;
}

.dashboard-container .product-dashboard-table .action a {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  font-size: 10px;
  color: #fff;
  text-align: center;
  display: inline-block;
}

.dashboard-container .product-dashboard-table .action a i {
  line-height: 30px;
}

.dashboard-container .product-dashboard-table .action .list-inline .list-inline-item:not(:last-child) {
  margin-right: 0px;
}

.dashboard-container .product-dashboard-table .action .edit {
  background: #59d659;
}

.dashboard-container .product-dashboard-table .action .view {
  background: #50c6e9;
}

.dashboard-container .product-dashboard-table .action .delete {
  background: #ff5252;
}

.submit-coupon .form-control, .submit-coupon .sbHolder {
  margin-bottom: 30px;
}

.submit-coupon button {
  margin-top: 20px;
}

.deals-categories {
  padding: 100px 0 0 0;
}

.deals-categories .border-bottom {
  padding-bottom: 70px;
}

@media (max-width: 768px) {
  .hero-area-2 {
    padding: 60px 0;
  }
}

.hero-area-2 .content-block {
  padding-bottom: 30px;
}

.hero-area-2 form {
  margin-bottom: 13px;
}

.hero-area-2 .coupon-ig {
  margin-bottom: 6px;
  /* WebKit, Blink, Edge */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10-11 */
  /* Microsoft Edge */
}

.hero-area-2 .coupon-ig input {
  background: #3EA3FF;
  color: #fff;
  border-color: transparent;
}

.hero-area-2 .coupon-ig .input-group-addon {
  background: #3EA3FF;
  color: #fff;
  border-left: none;
}

.hero-area-2 .coupon-ig .input-group-addon i {
  font-size: 17px;
  padding-right: 7px;
}

.hero-area-2 .coupon-ig .form-control::-webkit-input-placeholder {
  color: white;
}

.hero-area-2 .coupon-ig .form-control:-moz-placeholder {
  color: white;
}

.hero-area-2 .coupon-ig .form-control::-moz-placeholder {
  color: white;
}

.hero-area-2 .coupon-ig .form-control:-ms-input-placeholder {
  color: white;
}

.hero-area-2 .coupon-ig .form-control::-ms-input-placeholder {
  color: white;
}

.hero-area-2 .category-search .form-control {
  background: #fff;
}

.best-deal .block .tab-content {
  margin-top: 30px;
}

.category-block {
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.09);
  padding: 20px 25px;
  border-radius: 3px;
  margin-bottom: 25px;
}

.category-block .header {
  margin-bottom: 20px;
  border-bottom: 3px solid #F4F7F9;
  text-align: center;
}

.category-block .header h4 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
}

.category-block .header .icon-bg-1 {
  background: rgb(70, 72, 74);
  box-shadow: 0 0 0px 4px #ddd;
}

.category-block .header .icon-bg-2 {
  background: #a676b8;
  box-shadow: 0 0 0px 4px rgba(166, 118, 184, 0.35);
}

.category-block .header .icon-bg-3 {
  background: #fe7e17;
  box-shadow: 0 0 0px 4px rgba(254, 126, 23, 0.35);
}

.category-block .header .icon-bg-4 {
  background: #EC3A56;
  box-shadow: 0 0 0px 4px rgba(236, 58, 86, 0.35);
}

.category-block .header .icon-bg-5 {
  background: #1DBFFF;
  box-shadow: 0 0 0px 4px rgba(29, 191, 255, 0.35);
}

.category-block .header .icon-bg-6 {
  background: #02d3a4;
  box-shadow: 0 0 0px 4px rgba(2, 211, 164, 0.35);
}

.category-block .header .icon-bg-7 {
  background: #bc02d3;
  box-shadow: 0 0 0px 4px rgba(188, 2, 211, 0.35);
}

.category-block .header .icon-bg-8 {
  background: #025fd3;
  box-shadow: 0 0 0px 4px rgba(2, 95, 211, 0.35);
}

.category-block .header i {
  margin-right: 6px;
  color: #fff;
  font-size: 13px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 6px;
  border-radius: 40px;
}

.category-block .category-list li {
  display: block;
  font-size: 14px;
  border-bottom: 1px solid #F4F7F9;
  padding: 10px 0;
}

.category-block .category-list li:last-child {
  border: none;
}

.category-block .category-list li a {
  display: block;
  color: #777;
  font-size: 13px;
}

.category-block .category-list li a span {
  float: right;
  font-size: 10px;
  background: #F4F7F9;
  border-radius: 10px;
  padding: 3px 8px;
  color: #84919B;
}

.category-block .category-list li a:hover {
  text-decoration: none;
}

.category-block .category-list li a i {
  margin-right: 6px;
  font-size: 12px;
  color: #3EA3FF;
  vertical-align: middle;
}

.search-result {
  padding: 20px;
  margin-bottom: 20px;
}

.search-result p {
  margin: 0;
}

.category-search-filter {
  background: #f7f7f7;
  padding: 20px;
}

.category-search-filter strong {
  margin: 0 5px;
}

.category-search-filter .view {
  text-align: right;
}

.category-search-filter .view ul {
  display: inline-block;
}

.category-search-filter .view ul li:not(:last-child) {
  margin-right: 10px;
}

.category-search-filter .view ul li a {
  font-size: 15px;
}

.category-search-filter .view .view-switcher {
  background: #fff;
  padding: 10px 15px;
}

.nice-select {
  float: none;
  display: inline-block;
  border-radius: 0;
}

.nice-select .list {
  border-radius: 0;
}

.product-item {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.product-item .card {
  border: none;
}

.product-item .card .card-body {
  padding: 20px;
}

.product-item .card .card-body .card-title {
  font-size: 28px;
  text-align: center;
}

.product-item .card .card-body .card-text {
  color: #777;
}

.product-item .thumb-content {
  position: relative;
  padding-top: 20px;
}

.product-item .thumb-content .price {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #3EA3FF;
  color: #fff;
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
}

.product-item .product-meta {
  margin-bottom: 15px;
}

.product-item .product-meta li {
  margin-right: 15px;
}

.product-item .product-meta li a {
  color: #888;
  font-size: 12px;
}

.product-item .product-meta li a i {
  margin-right: 4px;
}

.product-item .product-ratings li {
  margin-right: 1px;
}

.product-item .product-ratings li i {
  font-size: 18px;
  color: #dedede;
  cursor: pointer;
}

.product-item .product-ratings .selected i {
  color: #3EA3FF;
}

.product-details .product-title {
  font-size: 25px;
  font-weight: 600;
}

.product-details .tab-title {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.product-details .product-meta li {
  color: #888;
  margin-right: 25px;
}

.product-details .product-meta li a {
  color: #333;
  margin-left: 3px;
}

.product-details .content {
  padding-top: 40px;
}

.product-details .content h3 {
  font-size: 20px;
  font-weight: bold;
}

.product-details .content p {
  color: #888;
  line-height: 22px;
}

.product-details .content .nav {
  text-align: center;
  margin-bottom: 5px;
}

.product-details .content .nav .nav-item {
  margin-right: 3px;
}

.product-details .content .nav .nav-item .nav-link {
  padding: 20px 30px;
  border-radius: 0;
  background: #fff;
}

.product-details .content .nav .nav-item .nav-link:hover {
  background: #3EA3FF;
  color: #fff;
}

.product-details .content .nav .nav-item .nav-link.active {
  background: #3EA3FF;
}

.product-details .content .tab-content {
  background: #fff;
  padding: 30px;
}

.product-slider {
  padding: 40px 0;
}

.product-slider .carousel-indicators li {
  background: #888;
}

.product-slider .carousel-indicators li.active {
  background: #3EA3FF;
}

.product-table {
  margin-top: 20px;
}

.product-table tr td:first-child {
  background: #f9f9f9;
}

.product-table tr td {
  padding-left: 25px;
}

.product-review img {
  width: 60px;
  margin-right: 20px;
}

.product-review .name h5 {
  font-size: 18px;
  margin-bottom: 5px;
}

.product-review .media-body {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 2px;
}

.product-review .review-submission h5 {
  font-size: 16px;
  color: #888;
}

.product-review .review-submission .rate {
  margin-bottom: 20px;
}

.product-review .review-submission .rate i {
  margin: 0 5px;
}

.product-review .review-submission .form-control {
  margin-bottom: 8px;
  border-radius: 0;
}

.product-review .review-submission input[type="email"], .product-review .review-submission input[type="password"], .product-review .review-submission input[type="text"], .product-review .review-submission input[type="tel"] {
  height: 40px;
}

.widget {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 25px 30px 30px;
  margin-bottom: 30px;
  border-radius: 2px;
}

.widget .widget-header {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
}

.widget.category-list ul li:not(:last-child) {
  border-bottom: 1px solid #F4F7F9;
}

.widget.category-list ul li a {
  padding: 8px 0;
  display: block;
}

.widget.category-list ul li a span {
  float: right;
  font-size: 10px;
  background: #F4F7F9;
  border-radius: 10px;
  padding: 3px 8px;
  color: #84919B;
}

.widget.filter .nice-select {
  width: 100%;
}

.widget.filter .nice-select .list {
  width: 100%;
}

.widget.price-range b {
  font-size: 13px;
  color: #888;
}

.widget.price-range .slider.slider-horizontal {
  width: 124px;
}

.widget.price-range .slider-handle {
  background: #3EA3FF;
}

.widget.price-range .slider-track {
  margin-top: -2px;
  height: 5px;
  box-shadow: none;
  border-radius: 0;
  background: #f5f5f5;
}

.widget.price-range .slider-selection {
  background: #dedede;
  box-shadow: none;
}

.widget.price {
  background: #3EA3FF;
}

.widget.price h4 {
  color: #fff;
}

.widget.price p {
  color: #fff;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin: 0;
}

.widget.map {
  padding: 10px;
}

.widget.map #map {
  height: 300px;
}

.widget.category ul.category-list li {
  margin-bottom: 8px;
}

.widget.category ul.category-list li a {
  font-size: 15px;
  letter-spacing: 0;
  display: block;
  color: #848484;
}

.widget.category ul.category-list li a:hover {
  color: #3EA3FF;
}

.widget.category ul.category-list li:last-child {
  margin-bottom: 5px;
}

.widget.archive ul.archive-list li {
  margin-bottom: 10px;
}

.widget.archive ul.archive-list li:last-child {
  margin-bottom: 5px;
}

.widget.archive ul.archive-list li a {
  font-size: 15px;
  letter-spacing: 0;
  display: block;
  color: #848484;
}

.widget.archive ul.archive-list li a:hover {
  color: #3EA3FF;
  text-decoration: none;
}

.widget.share-coupon .sbHolder, .widget.share-coupon .form-control {
  margin-bottom: 10px !important;
}

.widget.share-coupon button {
  width: 100%;
}

.widget.coupon {
  background: #3EA3FF;
  padding: 35px 30px 40px 30px;
}

.widget.coupon p {
  font-size: 20px;
  line-height: 1.4;
  color: #fff;
  margin-bottom: 25px;
}

.widget.rate .starrr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.widget.rate .starrr i {
  font-size: 20px;
  cursor: pointer;
  color: #e6b322;
  padding: 0 5px;
}

.widget.filter {
  padding-bottom: 17px;
}

.widget.filter .form-1 {
  margin-bottom: 20px;
}

.widget.filter .form-check-label {
  font-size: 14px;
  color: #848484;
}

.widget.filter .form-check-label input {
  margin-right: 10px;
}

.widget.filter .form-check-label input[type=checkbox]:checked .form-check-label {
  color: #3EA3FF !important;
}

.widget.filter .form-check-label:hover {
  color: #3EA3FF;
}

.widget.user {
  padding-top: 50px;
}

.widget.user .image img {
  width: 157px;
  height: 157px;
  border-radius: 100%;
  border: 7px solid #dedede;
}

.widget.user h5 {
  font-size: 20px;
  margin-top: 23px;
  font-weight: bold;
  margin-bottom: 10px;
}

.widget .welcome-message h2 {
  font-size: 30px;
  line-height: 54px;
  letter-spacing: .05em;
}

.widget .welcome-message p {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0;
  color: #848484;
}

.widget.personal-info .form-group, .widget.change-password .form-group, .widget.change-email .form-group {
  margin-bottom: 25px;
}

.widget.personal-info .form-group i, .widget.change-password .form-group i, .widget.change-email .form-group i {
  color: white;
  background: #3EA3FF;
  font-size: 25px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin-right: 15px;
  border-radius: 100%;
}

@media (max-width: 768px) {
  .widget.personal-info .form-group i, .widget.change-password .form-group i, .widget.change-email .form-group i {
    margin-bottom: 20px;
  }
}

.widget.personal-info .form-check, .widget.change-password .form-check, .widget.change-email .form-check {
  margin-bottom: 25px;
}

.widget.personal-info .form-check .form-check-label input, .widget.change-password .form-check .form-check-label input, .widget.change-email .form-check .form-check-label input {
  margin-right: 7px;
}

.widget.personal-info button, .widget.change-password button, .widget.change-email button {
  margin-top: 5px;
  margin-bottom: 10px;
}

.widget.product-shorting .form-check {
  margin-bottom: 4px;
}

@media (max-width: 768px) {
  .widget.search {
    margin-top: 30px;
  }
}

.widget.search .input-group .form-control {
  border: none;
}

.widget.search .input-group .input-group-addon {
  background: #3EA3FF;
  color: #fff;
  border: none;
}

.widget.search .input-group .input-group-addon i {
  font-size: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.call-to-action .content-holder h2 {
  color: #fff;
  font-weight: 600;
}

.call-to-action .content-holder .store-btn-holder {
  margin-top: 35px;
}

@media (max-width: 768px) {
  .call-to-action .content-holder .store-btn-holder {
    margin-top: 25px;
  }
  .call-to-action .content-holder .store-btn-holder a:first-child img {
    margin-bottom: 10px;
  }
}

.call-to-action .content-holder .store-btn-holder a {
  margin-right: 15px;
}

.subscription {
  position: relative;
  padding: 80px 0;
}

.subscription .content-block h2 {
  font-size: 43px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .subscription .content-block h2 {
    font-size: 30px;
  }
}

.subscription .content-block .form-control {
  display: inline-block;
  background-color: #fff;
}

.subscription .content-block input {
  width: 69%;
}

.subscription .content-block button {
  margin-left: 10px;
  width: 30%;
}

@media (max-width: 768px) {
  .subscription .content-block button {
    width: auto;
  }
}

.client-slider-03 {
  padding: 30px 0 0 0;
}

.stores {
  padding-bottom: 70px;
  padding-top: 70px;
}

.stores .block {
  margin-bottom: 30px;
}

.stores .block:last-child {
  margin-bottom: 0;
}

.stores .store-letter {
  font-size: 20px;
  margin-bottom: 16px;
}

.stores hr {
  margin-bottom: 25px;
}

.stores ul.store-list li a {
  display: block;
  font-size: 15px;
  color: #666;
  margin-bottom: 25px;
}

.stores ul.store-list li a:hover {
  text-decoration: none;
  color: #3EA3FF;
}

.store-coupon {
  padding: 30px 0;
}

.store-coupon .block {
  padding: 25px 30px 30px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .store-coupon .block .media {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .store-coupon .block .media .image-content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-bottom: 30px;
  }
}

.store-coupon .block .media .media-body {
  margin: 0 75px;
}

.store-coupon .block .media .media-body h4 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.store-coupon .block .media .media-body ul li {
  font-weight: bold;
  font-size: 17px;
  padding: 0 15px;
  border-right: 1px solid #dedede;
}

.store-coupon .block .media .media-body ul li:last-child {
  border: none;
}

.store-coupon .block .media .media-body ul li a {
  color: #3EA3FF;
}

.store-coupon .block .media .media-body p {
  font-size: 14px;
  line-height: 26px;
  color: #848484;
  letter-spacing: 0;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .store-coupon .block .media .media-body {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .store-coupon .block .media .media-body {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .store-coupon .block .social-content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 100%;
    padding-top: 30px;
    text-align: center;
  }
}

.store-coupon .block .social-content ul.social-circle-icons li {
  margin-right: 7px;
}

.store-coupon .block .social-content ul.social-circle-icons li:last-child {
  margin-right: 0;
}

.store-coupon .block .social-content ul.social-circle-icons li a {
  display: block;
  font-size: 15px;
  line-height: 38px;
  color: #3EA3FF;
  height: 38px;
  width: 38px;
  border: 2px solid #3EA3FF;
  background: #fff;
  border-radius: 100%;
}

.store-coupon .block .social-content ul.social-circle-icons li a:hover {
  text-decoration: none;
  color: #fff;
  background: #3EA3FF;
}

.store-coupon .block .social-content .share {
  font-size: 15px;
  line-height: 28px;
  color: #848484;
}

@media (max-width: 768px) {
  .store-coupon .block .social-content .share {
    text-align: center !important;
    margin-top: 10px;
  }
}

.store {
  padding: 0 0 80px 0;
}

.store .block .store-nav .nav-item .nav-link {
  font-size: 17px;
  padding: 0 40px 0 0;
  margin-bottom: 15px;
}

.submit-coupon-sec {
  padding-top: 70px;
}

.submit-coupon-sec form .form-group label {
  color: #666;
  font-size: 15px;
  font-weight: bold;
}

.submit-coupon-sec form .form-group .form-control {
  background: #fff;
}

.submit-coupon-sec form .form-group .input-group input {
  border-right: none;
}

.submit-coupon-sec form .form-group .input-group input:focus + .input-group-addon {
  border: 1px solid #3EA3FF;
}

.submit-coupon-sec form .form-group .input-group .input-group-addon {
  background: #fff;
  border-left: none !important;
}

.submit-coupon-sec form .form-group .input-group .input-group-addon i {
  font-size: 15px;
  color: #848484;
  padding-right: 8px;
}

.submit-coupon-sec form .coupon-type {
  padding: 40px 0 22px 0;
}

.submit-coupon-sec form .coupon-type ul.coupon-types li {
  margin-right: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .submit-coupon-sec form .coupon-type ul.coupon-types li {
    margin: 5px;
  }
}

.submit-coupon-sec form .coupon-type ul.coupon-types li a {
  color: #3EA3FF;
  display: block;
  font-size: 14px;
  text-decoration: none;
  padding: 25px 0;
  width: 170px;
  text-align: center;
  border: 1px solid #3EA3FF;
  border-radius: 3px;
}

.submit-coupon-sec form .coupon-type ul.coupon-types li a i {
  display: block;
  text-align: center;
  font-size: 40px;
}

.submit-coupon-sec form .coupon-type ul.coupon-types li.active a {
  background: #3EA3FF;
  color: #fff;
}

.submit-coupon-sec form button {
  margin-top: 10px;
}

.submit-coupon-sec p {
  margin: 70px 0 0 0;
  font-size: 14px;
  line-height: 26px;
  color: #848484;
  letter-spacing: 0.01em;
}

.submit-coupon-sec p a {
  color: #3EA3FF;
  text-decoration: none !important;
}

article {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 2px;
}

article .image, article .video, article .post-slider {
  margin-bottom: 25px;
}

article img {
  border-radius: 3px;
}

article .video {
  position: relative;
}

article .video:before {
  border-radius: 3px;
}

article .video .video-button {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

article .video .video-box a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

article .video .video-box a img {
  width: 79px;
}

article .video .video-box a iframe {
  width: 100%;
  height: 100%;
}

article .post-slider .owl-nav {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

article .post-slider .owl-nav i {
  font-size: 30px;
  color: #fff;
}

article .post-slider .owl-nav [class*=owl-] {
  padding: 7px 16px;
  background-color: rgba(255, 255, 255, 0.35);
}

article .post-slider .owl-nav [class*=owl-]:hover {
  background-color: #3EA3FF;
}

article h3 {
  margin-bottom: 5px;
}

article ul {
  margin-bottom: 15px;
}

article ul li {
  margin-right: 10px !important;
  color: #848484;
}

article ul li a {
  color: #3EA3FF;
}

article p {
  line-height: 26px;
  color: #848484;
  margin-bottom: 24px;
}

article .btn {
  margin-bottom: 10px;
}

.single-blog {
  padding-top: 50px !important;
}

article.single-post img {
  margin-bottom: 35px;
}

article.single-post p {
  margin-bottom: 35px;
}

.sign-in-section {
  padding: 30px 0;
}

.sign-in-welcome-banner {
  text-align: -webkit-center;
}

.sign-up-card-bg {
  background-color: #46484A;
}

.sign-up-card-bg h2 {
  color: #fff;
}

.sign-up-card-bg p {
  color: #fff;
}

.signin-help {
  margin-top: 20px;
}

.comment {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 2px;
}

.comment h4 {
  font-size: 20px;
  letter-spacing: 0;
}

.comment button {
  margin: 10px 0;
}

.popup .block-1 {
  padding: 20px 80px;
  background: #F6F8FA;
}

.popup .block-1 .media .img-content {
  height: 80px;
  width: 130px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.popup .block-1 .media .media-body h4 {
  font-size: 24px;
}

.popup .block-2 {
  padding: 50px 80px;
  background: #fff;
}

.popup .block-2 h6 {
  margin-bottom: 20px;
}

.popup .block-2 p {
  width: 100%;
  text-align: center;
  line-height: 60px;
  text-transform: uppercase;
  background: #fff6f7;
  border: 2px dashed #3EA3FF;
  border-radius: 3px;
  margin-bottom: 40px;
}

.popup .block-2 form .form-group label {
  display: block;
  color: #666;
}

.popup .block-2 form .form-group a {
  float: right;
  margin-top: -5px;
}

.footer {
  background: #232425;
}

.footer .block h4 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 25px;
}

.footer .block.about img {
  margin-bottom: 25px;
}

.footer .block.about p {
  line-height: 26px;
}

.footer .block ul li {
  margin-bottom: 10px;
}

.footer .block ul li a {
  display: block;
  font-size: 14px;
  color: #797979;
}

.footer .block ul li a:hover {
  color: #3EA3FF;
  text-decoration: none !important;
}

.footer .block ul.terms-list {
  margin-top: 10px;
}

.footer .block ul.terms-list li a {
  padding-right: 10px;
}

@media (max-width: 768px) {
  .footer .block:last-child {
    margin-bottom: 30px;
  }
}

.footer .block-2 {
  padding: 18px 30px;
  background: #46484A;
  border-radius: 2px;
}

.footer .block-2.app-promotion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.footer .block-2.app-promotion p {
  margin-left: 20px;
  margin-top: 3px;
  color: #fff;
}

.footer .block-2.app-promotion p a {
  color: #fff !important;
}

.footer .block-2.discount-coupon p {
  color: #fff;
  margin-bottom: 0;
}

.footer .block-2.discount-coupon p a {
  color: #3EA3FF;
}

.footer-bottom {
  position: relative;
  background: #363839;
  padding: 25px 0;
}

@media (max-width: 480px) {
  .footer-bottom {
    text-align: center;
  }
}

.footer-bottom .copyright p {
  color: #c7c7c7;
  margin-bottom: 0;
}

.footer-bottom .copyright span {
  color: #c7c7c7;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.social-media-icons {
  text-align: center !important;
}

.social-media-icons li {
  display: inline-block;
}

.social-media-icons li a {
  color: #848484;
  padding: 0 20px;
}

.social-media-icons li a:hover {
  color: #fff !important;
  text-decoration: none !important;
}

.top-to {
  position: absolute;
  cursor: pointer;
  right: 20px;
  bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.top-to a {
  width: 40px;
  height: 40px;
  background: #3EA3FF;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.top-to a i {
  line-height: 40px;
}

.top-to a:hover {
  text-decoration: none !important;
}

.top-bar-btns {
  padding: 10px;
  margin-right: 10px;
}

.btn-primary {
  background-color: #3EA3FF !important;
}

.home-logo {
  width: 175px;
}

.home-logo-footer {
  width: 100px;
  margin-right: 3px;
}

.home-search-btn {
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Muli, sans-serif;
}

.home-new-client-btn {
  height: 55px;
}

.category-form {
  width: 100%;
}

.category-form .card-block {
  padding: 20px;
}

.card-icon {
  margin-left: 14%;
}

.category-bg {
  background: url("assets/img/pages/coins.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.category-content-block {
  font-size: 65px;
  letter-spacing: 0.05em;
  height: 200px;
}

.btn-secondary {
  background: #3EA3FF;
  color: #fff;
  border-color: #fff;
}

.blog-bg {
  background: url("assets/img/pages/blog.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-search-icon {
  width: 30px;
  margin-top: 12px;
  cursor: pointer;
}

.blog-search-input {
  font-family: inherit;
}

.blog-link {
  cursor: pointer;
  color: #3EA3FF;
  outline: none;
}

.blog-show-all {
  margin-bottom: 25px;
}


.blog-show-all button {
  padding: 12px;
}

.blog-line-item {
  color: #848484;
}

.blog-results {
  margin-bottom: 20px;
}

.blog-results-link {
  outline : none;
  cursor: pointer;
}

.blog-results-link:hover {
  color: #3EA3FF;
}

.blog-post {
    background: none;
    padding: 30px;
    border: none !important;
    box-shadow: none !important;
    margin-bottom: 30px !important;
    padding: 0px !important;
}

.blog-post-back {
  background: none;
  border-top: 1px #ccc solid !important;
  border-bottom: 1px #ccc solid !important;
  box-shadow: none !important;
  margin-bottom: 30px !important;
  padding: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
}

.blog-post-text {
  margin-bottom: 0px !important;
}

.blog-post-text p {
  color: #333 !important;
}

.blog-post-related-posts {
  border-bottom: 1px #ccc solid;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.related-posts-section-title {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
}

.related-posts-container {
  text-align: -webkit-center;
}

.related-post-title {
  margin-top: 5px;
}

.related-post-thumbnail {
  outline : none;
  cursor: pointer;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: Muli, sans-serif;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: Muli, sans-serif;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: Muli, sans-serif;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: Muli, sans-serif;
}
/**************************************************/

/* Style the form */
#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  padding: 40px;
  width: 70%;
  min-width: 300px;
}

/* Style the input fields */
input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}

/* Mark input boxes that gets an error on validation: */
input.invalid {
  background-color: #ffdddd;
}

/* Hide all steps by default: */
.tab {
  display: none;
}

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none; 
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #4CAF50;
}
 /**************************************************/
/*# sourceMappingURL=maps/style.css.map */
